import {
  faEye,
  faEyeSlash,
  faKey,
  faUnlock,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Link,
  Modal,
  ModalDialog,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { generate as generatePassword } from "generate-password-browser";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useCopyToClipboard, useDocsLink, useSignOut } from "hooks";
import { passwordRegExp } from "utils/validation";
import { useKeychain } from "wrappers";

const validationSchema = yup.object({
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Please repeat your password")
    .defined(),
  password: yup
    .string()
    .trim()
    .min(
      10,
      "Your password must contain at least 10 characters, lower case and upper case letters, and numbers."
    )
    .matches(
      passwordRegExp,
      "Your password must contain at least 10 characters, lower case and upper case letters, and numbers."
    )
    .required()
    .defined(),
});

const passwordSuggestion = () =>
  generatePassword({
    length: 14,
    lowercase: true,
    numbers: true,
    strict: true,
    uppercase: true,
  });

type SetupKeychainFormValues = yup.InferType<typeof validationSchema>;

interface SetupKeychainProps {}

const SetupKeychain: React.FC<SetupKeychainProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  const docsBaseLink = useDocsLink();
  const { createKeychain } = useKeychain();
  const [creating, setCreating] = useState(false);
  const [suggestedPassword, setSuggestedPassword] = useState<
    string | undefined
  >();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [validationErrors, setValidationErrors] = useState<
    string[] | undefined
  >(undefined);
  const isValid = validationErrors === undefined;
  const validate = useCallback(
    async (values: SetupKeychainFormValues): Promise<boolean> => {
      try {
        await validationSchema.validate(values);
        setValidationErrors(undefined);
        return true;
      } catch (error) {
        setValidationErrors((error as yup.ValidationError).errors);
        return false;
      }
    },
    [setValidationErrors]
  );
  useEffect(() => {
    if (password === undefined) {
      return;
    }
    validate({
      confirmPassword: confirmPassword || "",
      password,
    });
  }, [password, confirmPassword, validate]);
  const handleSubmit = useCallback(
    async (
      password: string | undefined,
      confirmPassword: string | undefined
    ) => {
      setCreating(true);
      const isValid = await validate({
        confirmPassword: confirmPassword || "",
        password: password || "",
      });
      if (isValid) {
        await createKeychain(password!.trim());
      }
      setCreating(false);
    },
    [setCreating, createKeychain, validate]
  );
  const handlePasswordFocus = useCallback(
    (password: string | undefined) => {
      if (suggestedPassword) {
        return;
      }
      if (!password || !password.trim().length) {
        const newPassword = passwordSuggestion();
        setSuggestedPassword(newPassword);
      }
    },
    [setSuggestedPassword, suggestedPassword]
  );
  const handlePasswordSuggestionUse = useCallback(
    (suggestedPassword: string) => {
      copyToClipboard(suggestedPassword);
      enqueueSnackbar("Password copied to clipboard", {
        autoHideDuration: 1500,
      });
      setPassword(suggestedPassword);
      setConfirmPassword(suggestedPassword);
      setSuggestedPassword(undefined);
    },
    [copyToClipboard, enqueueSnackbar, setPassword, setConfirmPassword]
  );
  const onSignOut = useSignOut();
  return (
    <Modal open={true}>
      <ModalDialog>
        <DialogTitle>
          <FontAwesomeIcon fixedWidth={true} icon={faKey} /> Keychain
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(password, confirmPassword);
            }}
          >
            <Stack>
              <Stack spacing={0.5}>
                <Typography>
                  The Keychain lets you confidentially store dataset encryption
                  keys and other secrets.
                </Typography>
                <Typography>
                  Among other features, this enables you to reuse datasets
                  across data clean rooms without having to re-upload them to
                  Decentriq.
                </Typography>
                <Typography>
                  The Keychain must be enabled to interact with the Decentriq
                  Platform. Please set your password now.
                </Typography>
              </Stack>
              <FormControl>
                <FormLabel>Create new Keychain password</FormLabel>
                <Tooltip
                  disableHoverListener={true}
                  onClose={() => setSuggestedPassword(undefined)}
                  open={!!suggestedPassword}
                  title={
                    <Box
                      onClick={(e) => {
                        handlePasswordSuggestionUse(suggestedPassword!);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography level="body-sm" sx={{ color: "inherit" }}>
                        Click here to use suggested password:
                        <pre style={{ margin: ".5rem 0" }}>
                          {suggestedPassword}
                        </pre>
                      </Typography>
                    </Box>
                  }
                >
                  <Input
                    autoComplete="new-password"
                    endDecorator={
                      <IconButton onClick={toggleShowPassword}>
                        {showPassword ? (
                          <FontAwesomeIcon
                            fixedWidth={true}
                            icon={faEyeSlash}
                          />
                        ) : (
                          <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                        )}
                      </IconButton>
                    }
                    onBlur={() =>
                      setTimeout(() => setSuggestedPassword(undefined), 300)
                    }
                    onChange={(event) => {
                      const newPassword = event.target.value;
                      setPassword(newPassword);
                      if (suggestedPassword) {
                        if (!newPassword.length) {
                          handlePasswordFocus(newPassword);
                        }
                      } else {
                        if (newPassword.trim().length) {
                          setSuggestedPassword(undefined);
                        }
                      }
                    }}
                    onFocus={() => handlePasswordFocus(password)}
                    type={showPassword ? "text" : "password"}
                    value={password || ""}
                  />
                </Tooltip>
              </FormControl>
              <FormControl>
                <FormLabel>Repeat password</FormLabel>
                <Input
                  autoComplete="new-password"
                  endDecorator={
                    <IconButton onClick={toggleShowConfirmPassword}>
                      {showConfirmPassword ? (
                        <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                      )}
                    </IconButton>
                  }
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword || ""}
                />
              </FormControl>
              {!isValid ? (
                <Alert color="danger">
                  <Box>
                    <Box>
                      <strong>Invalid password:</strong>
                    </Box>
                    <ul style={{ margin: 0, paddingInlineStart: "1rem" }}>
                      {validationErrors?.map((validationError, index) => (
                        <li key={index}>{validationError}</li>
                      ))}
                    </ul>
                  </Box>
                </Alert>
              ) : null}
              <AccordionGroup variant="outlined">
                <Accordion>
                  <AccordionSummary>How does it work?</AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={0.5}>
                      <Typography>
                        The Keychain operates like a traditional password
                        manager. It derives an encryption key from your
                        password. This key is used to locally encrypt the
                        secrets (e.g. dataset encryption keys) you want to store
                        in the Keychain.
                      </Typography>
                      <Typography>
                        The encrypted secrets are then stored on the Decentriq
                        Platform. They are retrieved when needed and the same
                        password-derived encryption key is used to decrypt them.
                      </Typography>
                      <Typography>
                        As Decentriq does not have access to your password,
                        Decentriq can never access the secrets stored in the
                        Keychain.
                      </Typography>
                      <Typography>
                        If you lose your password, you will have to reset it and
                        lose access to all previously stored secrets.
                      </Typography>
                      <Typography>
                        For more information, please{" "}
                        <Link
                          href={`${docsBaseLink}/welcome-to-decentriq#setting-up-your-keychain`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          check the documentation
                        </Link>
                        .
                      </Typography>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </Stack>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={creating} onClick={onSignOut}>
            Sign out
          </Button>
          <Button
            color="primary"
            loading={creating}
            loadingPosition="start"
            onClick={() => handleSubmit(password, confirmPassword)}
            startDecorator={<FontAwesomeIcon icon={faUnlock} />}
            variant="solid"
          >
            Activate Keychain
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(SetupKeychain);
