import { Tooltip } from "@mui/joy";
import { Avatar, type SxProps, type Theme } from "@mui/material";
import { green, grey } from "@mui/material/colors";

interface DataRoomTableParticipantAvatarProps {
  name: string;
  active?: boolean;
  size?: string;
  tooltip?: string;
  shifted?: boolean;
  sx?: SxProps<Theme>;
}

const DataRoomTableParticipantAvatar: React.FC<
  DataRoomTableParticipantAvatarProps
> = ({ name, tooltip, shifted, active = false, size = "32px", sx = {} }) => (
  <Avatar
    style={{
      backgroundColor: `${active ? green["50"] : grey["100"]}`,
      color: `${active ? green["600"] : grey["400"]}`,
      height: size,
      margin: "0 2px",
      position: "relative",
      width: size,
      ...(shifted ? { marginLeft: "-8px" } : {}),
    }}
    sx={sx}
  >
    <Tooltip title={tooltip || name}>
      {/* Tooltip expects a react element (not ReactNode) as children, therefore wrapping it in a fragment */}
      <>{name?.substring(0, 2).toUpperCase()}</>
    </Tooltip>
  </Avatar>
);

export default DataRoomTableParticipantAvatar;
