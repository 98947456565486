import {
  MatchingColumnFormat,
  TableColumnHashingAlgorithm,
} from "@decentriq/graphql/enums";
import {
  CollaborationTypes,
  MediaDataRoomUserRole,
  RawMatchingID,
} from "@decentriq/types";
import {
  faBullhorn as falBullhorn,
  faChartSimple as falChartSimple,
  faUsersRays as falUsersRays,
  type IconDefinition as IconDefinitionLight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBullhorn as fatBullhorn,
  faChartSimple as fatChartSimple,
  faUsersRays as fatUsersRays,
  type IconDefinition as IconDefinitionThin,
} from "@fortawesome/pro-thin-svg-icons";

// NOTE: Came from "models/dataRoom/matchingId"
export const matchingIdTypeToGqlValues: Record<
  RawMatchingID,
  [MatchingColumnFormat, TableColumnHashingAlgorithm | null]
> = {
  [RawMatchingID.EMAIL]: [MatchingColumnFormat.Email, null],
  [RawMatchingID.HASHED_EMAIL]: [
    MatchingColumnFormat.Email,
    TableColumnHashingAlgorithm.Sha256Hex,
  ],
  [RawMatchingID.HASHED_PHONE_NUMBER]: [
    MatchingColumnFormat.PhoneNumberE164,
    TableColumnHashingAlgorithm.Sha256Hex,
  ],
  [RawMatchingID.PHONE_NUMBER]: [MatchingColumnFormat.PhoneNumberE164, null],
  [RawMatchingID.STRING]: [MatchingColumnFormat.String, null],
};

// NOTE: Came from "models/dataRoom/matchingId"
export const matchingIdTypes: { name: string; value: string }[] = [
  { name: "String", value: "string" },
  { name: "Email", value: "email" },
  { name: "Hashed email", value: "hashed_email" },
  { name: "Phone number", value: "phone_number" },
  { name: "Hashed phone number", value: "hashed_phone_number" },
];

// NOTE: Came from "features/dataLabs/models"
export const matchingIdTypeAndHashingAlgorithmPresentation = ({
  matchingIdFormat,
  matchingIdHashingAlgorithm,
}: {
  matchingIdFormat: MatchingColumnFormat;
  matchingIdHashingAlgorithm: TableColumnHashingAlgorithm | undefined | null;
}): string => {
  const hashed = Boolean(matchingIdHashingAlgorithm);
  switch (matchingIdFormat) {
    case MatchingColumnFormat.String: {
      return "String";
    }
    case MatchingColumnFormat.Email: {
      return hashed ? "SHA256 Hashed Email" : "Email";
    }
    case MatchingColumnFormat.PhoneNumberE164: {
      return hashed ? "SHA256 Hashed Phone (E.164)" : "Phone number (E.164)";
    }
    default:
      return "";
  }
};

// NOTE: Came from "features/mediaDataRoom/types"
export const DEFAULT_PARTICIPANTS_EMAILS = new Map<
  MediaDataRoomUserRole,
  string[]
>([
  [MediaDataRoomUserRole.Publisher, []],
  [MediaDataRoomUserRole.DataPartner, []],
  [MediaDataRoomUserRole.Advertiser, []],
  [MediaDataRoomUserRole.Observer, []],
  [MediaDataRoomUserRole.Agency, []],
]);

// NOTE: Came from "models/dataRoom/dataRoom.new"
export const mediaDataRoomCollaborationTypes = [
  CollaborationTypes.Insights,
  CollaborationTypes.Retargeting,
  CollaborationTypes.ExclusionTargeting,
  CollaborationTypes.Lookalike,
];

// NOTE: came from "models/dataRoom/dataRoom.new"
export enum DataRoomType {
  Media = "Media",
  DataScience = "DataScience",
  LookalikeMedia = "LookalikeMedia",
  MediaInsights = "MediaInsights",
  Measurement = "Measurement",
}

export interface DataRoomTypePresentation {
  iconLight: IconDefinitionLight;
  iconThin: IconDefinitionThin;
  title: string;
  description: string;
}

export const dataRoomTypePresentationMap: Record<
  DataRoomType,
  DataRoomTypePresentation
> = {
  [DataRoomType.DataScience]: {
    description: "Data Science",
    iconLight: falChartSimple,
    iconThin: fatChartSimple,
    title: "Advanced Analytics",
  },
  [DataRoomType.LookalikeMedia]: {
    description: "Insights & Activation",
    iconLight: falUsersRays,
    iconThin: fatUsersRays,
    title: "Lookalike Audience",
  },
  [DataRoomType.Media]: {
    description: "Insights & Activation",
    iconLight: falUsersRays,
    iconThin: fatUsersRays,
    title: "Top-Affinity & Retargeting",
  },
  [DataRoomType.MediaInsights]: {
    description: "Insights & Activation",
    iconLight: falUsersRays,
    iconThin: fatUsersRays,
    title: "Media",
  },
  [DataRoomType.Measurement]: {
    description: "Measurement",
    iconLight: falBullhorn,
    iconThin: fatBullhorn,
    title: "Measurement",
  },
};
