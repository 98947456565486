import { InfoTooltip } from "@decentriq/components";
import {
  type CollaboratingOrganizationFilter,
  type OrganizationDataRoomFilter,
} from "@decentriq/graphql/dist/types";
import { FormControl, FormLabel } from "@mui/joy";
import { endOfMonth, startOfMonth } from "date-fns";
import {
  type EventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import DatePicker from "./JoyDatePicker";

type AdminMonthPickerProps = {
  setQueryFilter: (
    filter: CollaboratingOrganizationFilter | OrganizationDataRoomFilter
  ) => void;
  defaultValue?: Date;
};

const AdminMonthPicker: React.FC<AdminMonthPickerProps> = ({
  setQueryFilter,
  defaultValue = null,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultValue);
  const toggleOpen: EventHandler<React.MouseEvent<HTMLElement>> = useCallback(
    (event) => {
      event.stopPropagation();
      setOpen((prevOpen) => !prevOpen);
    },
    []
  );
  useEffect(() => {
    // Do not fire a request if date is still being selected
    if (open) return;
    if (!selectedDate) {
      setQueryFilter({ activityPeriodOverlapsWithInterval: null });
      return;
    }
    const startOfMonthDate = startOfMonth(selectedDate);
    const endOfMonthDate = endOfMonth(selectedDate);
    setQueryFilter({
      activityPeriodOverlapsWithInterval: {
        from: startOfMonthDate.toISOString(),
        until: endOfMonthDate.toISOString(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, open]);
  return (
    <FormControl
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <FormLabel sx={{ color: "initial", marginRight: 1 }}>
        Activity period:
      </FormLabel>
      <DatePicker
        onChange={(date) => setSelectedDate(date)}
        onClose={() => setOpen(false)}
        open={open}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
          openPickerButton: {
            onClick: toggleOpen,
          },
          textField: {
            disabled: true,
            onClick: toggleOpen,
            placeholder: "Select month",
          },
        }}
        value={selectedDate}
        views={["month", "year"]}
      />
      <InfoTooltip
        marginLeft="0px"
        tooltip={
          <>
            The activity period starts when a DCR is activated (once either data
            has been provisioned or a computation has been run by an external
            user) and ends when it is deactivated or stopped.
          </>
        }
        top="0px"
      />
    </FormControl>
  );
};
AdminMonthPicker.displayName = "AdminMonthPicker";

export default memo(AdminMonthPicker);
