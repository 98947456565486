import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import { DatasetExportDialog } from "features/datasets";

type DatasetExportButtonProps = {
  datasetManifestHash: string;
};

const DatasetExportButton: React.FC<DatasetExportButtonProps> = memo(
  ({ datasetManifestHash }) => (
    <DatasetExportDialog
      datasetManifestHash={datasetManifestHash}
      renderTrigger={(open) => (
        <Button
          color="primary"
          onClick={open}
          startDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faFileExport} />
          }
          variant="solid"
        >
          Export dataset
        </Button>
      )}
    />
  )
);
DatasetExportButton.displayName = "DatasetExportButton";

export default DatasetExportButton;
