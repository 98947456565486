import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import CreateDataPartnerUsageConfigurationDialog from "../CreateDataPartnerUsageConfigurationDialog/CreateDataPartnerUsageConfigurationDialog";

const CreateDataPartnerUsageConfigurationButton: React.FC = () => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  const { displayConfiguration } = useDataPartnerPortal();
  const disabled = !displayConfiguration;
  return (
    <>
      <Tooltip
        title={
          disabled ? "Please first create a display configuration" : undefined
        }
      >
        <span>
          <Button
            color="primary"
            data-testid={
              testIds.dataPartnerPortal.usageConfigurations.createButton
            }
            disabled={disabled}
            onClick={openDialog}
            startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
            variant="solid"
          >
            Usage configuration
          </Button>
        </span>
      </Tooltip>
      <CreateDataPartnerUsageConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
      />
    </>
  );
};

CreateDataPartnerUsageConfigurationButton.displayName =
  "CreateDataPartnerUsageConfigurationButton";

export default memo(CreateDataPartnerUsageConfigurationButton);
