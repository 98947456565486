import { DOMAIN_REGEXP } from "constants/index";
import { faInfoCircle, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Switch,
  Tooltip,
} from "@mui/joy";
import { useUpdateEffect } from "ahooks";
import { memo, useCallback, useRef, useState } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
  useFormContext,
  useFormState,
} from "react-hook-form";

interface ConfigurationFormDomainFieldProps<F extends FieldValues> {
  textFieldName: Path<F>;
  checkboxFieldName: Path<F>;
  textFieldTestId?: string;
  checkboxTestId?: string;
}

type ConfigurationFormDomainFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormDomainFieldProps<F>
) => React.ReactNode;

const ConfigurationFormDomainField = memo(
  <F extends FieldValues>({
    textFieldName,
    checkboxFieldName,
    textFieldTestId,
    checkboxTestId,
  }: ConfigurationFormDomainFieldProps<F>) => {
    const [domain, setDomain] = useState<string>("");
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { control, clearErrors, watch, setValue, setError } =
      useFormContext<F>();
    const { errors } = useFormState({ control: control });
    const advertiserDomains = watch(textFieldName);
    const matchAnyDomain = watch(checkboxFieldName);
    useUpdateEffect(() => {
      if (matchAnyDomain) {
        setDomain("");
        setIsTouched(false);
        clearErrors(textFieldName);
        setValue(textFieldName, [] as PathValue<F, Path<F>>);
      }
    }, [matchAnyDomain]);
    useUpdateEffect(() => {
      // Handle check for already existing domains
      if (advertiserDomains?.includes(domain)) {
        setError(textFieldName, {
          message: "Domain is already in use",
          type: "custom",
        });
        // Handle check for domain validity
      } else if (domain && !domain.match(DOMAIN_REGEXP)) {
        setError(textFieldName, {
          message: "Domain is invalid",
          type: "custom",
        });
      } else {
        clearErrors(textFieldName);
      }
    }, [domain, advertiserDomains, clearErrors, setError]);
    const handleDomainChange = useCallback(
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setDomain(target.value);
        if (!isTouched) setIsTouched(true);
      },
      [isTouched]
    );
    const addAdvertiserDomainOnBlur: React.FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      (event) => {
        if (!event?.target?.value || !!errors?.advertiserDomains) return;
        setDomain("");
        setValue(textFieldName, [
          ...(advertiserDomains ?? []),
          event?.target?.value,
        ] as PathValue<F, Path<F>>);
        inputRef.current?.focus();
      },
      [advertiserDomains, errors?.advertiserDomains, setValue, textFieldName]
    );
    const addAdvertiserDomainOnEnter: React.KeyboardEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      (event) => {
        if (event.keyCode !== 13 || !event.target.value) return;
        addAdvertiserDomainOnBlur(event);
      },
      [addAdvertiserDomainOnBlur]
    );
    const removeAdvertiserDomain = useCallback(
      (domain: string) => {
        const allowedAdvertiserDomains = (advertiserDomains ?? []).filter(
          (advertiserDomain: string) => advertiserDomain !== domain
        );
        setValue(
          textFieldName,
          allowedAdvertiserDomains as PathValue<F, Path<F>>
        );
      },
      [advertiserDomains, setValue, textFieldName]
    );
    return (
      <Controller
        control={control}
        name={textFieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormLabel>
                  Advertiser domain(s)
                  <Box ml={0.5}>
                    <Tooltip title="The domain (ETLD+1) of the advertiser this config applies to. Users at an organization registered to this domain will be able to create clean rooms using this config.">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <Controller
                  control={control}
                  name={checkboxFieldName}
                  render={({ field }) => (
                    <FormControl
                      disabled={field.disabled}
                      sx={{ "--FormLabel-margin": 0 }}
                    >
                      <Switch
                        endDecorator={
                          <FormLabel sx={{ lineHeight: 1 }}>
                            Match any
                          </FormLabel>
                        }
                        size="sm"
                        variant="solid"
                        {...field}
                        data-testid={checkboxTestId}
                      />
                    </FormControl>
                  )}
                />
              </Box>
              <Input
                data-testid={textFieldTestId}
                disabled={matchAnyDomain}
                endDecorator={
                  domain && !error ? (
                    <IconButton>
                      <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                  ) : undefined
                }
                error={Boolean(error)}
                onBlur={addAdvertiserDomainOnBlur}
                onChange={handleDomainChange}
                onKeyDown={addAdvertiserDomainOnEnter}
                placeholder={`e.g. "example.com"`}
                slotProps={{ input: { ref: inputRef } }}
                value={domain}
              />
              <FormHelperText>{error?.message}</FormHelperText>
              {(advertiserDomains ?? []).length > 0 ? (
                <Stack direction="row" flexWrap="wrap" sx={{ mt: 1 }}>
                  {((advertiserDomains || []) as string[]).map((domain) => (
                    <Chip
                      endDecorator={
                        <ChipDelete
                          onDelete={() => removeAdvertiserDomain(domain)}
                        />
                      }
                      key={domain}
                      size="lg"
                    >
                      {domain?.toLowerCase()}
                    </Chip>
                  ))}
                </Stack>
              ) : null}
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormDomainField.displayName = "ConfigurationFormDomainField";

export default ConfigurationFormDomainField as ConfigurationFormDomainFieldComponent;
