import {
  DqDatasetExportFormProvider,
  type DqDatasetExportFormProviderSchemaType,
  DqDatasetSyncDrawer,
} from "@decentriq/components";
import { useCreateDatasetExportMutation } from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetExportMutation,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { useBoolean } from "@decentriq/hooks";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { CreateExportStep, SelectSyncStep, ShowStatusStep } from "./components";
import {
  DatasetExportProvider,
  type DatasetExportProviderProps,
  useDatasetExport,
} from "./contexts";

interface DatasetExportDialogProps {
  renderTrigger: (open: () => void) => React.ReactNode;
}

const DatasetExportDialog: React.FC<DatasetExportDialogProps> = memo(
  ({ renderTrigger }) => {
    const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const {
      datasetManifestHash,
      setDatasetExportId,
      setIsExportCreated,
      setDatasetExportError,
    } = useDatasetExport();
    const [syncType, setSyncType] = useState<DataTargetType | null>(null);
    const [createDatasetExportMutation] = useCreateDatasetExportMutation();
    const onSubmit = useCallback(
      async (
        createDatasetExportMutationVariables: DqDatasetExportFormProviderSchemaType
      ) => {
        await createDatasetExportMutation({
          onCompleted: ({
            createDatasetExport,
          }: CreateDatasetExportMutation) => {
            setDatasetExportId(createDatasetExport?.dataConnectorJob?.id);
            setIsExportCreated(true);
          },
          onError: (error) => {
            setDatasetExportError(error?.message);
            enqueueSnackbar(
              ...mapDraftDataRoomErrorToSnackbar(
                error,
                "The exporting process could not get started. Please try again by refreshing the page."
              )
            );
          },
          variables: {
            input: {
              ...createDatasetExportMutationVariables,
              manifestHash: datasetManifestHash,
            },
          },
        });
      },
      [
        createDatasetExportMutation,
        datasetManifestHash,
        enqueueSnackbar,
        setDatasetExportError,
        setDatasetExportId,
        setIsExportCreated,
      ]
    );
    useEffect(() => {
      if (!isOpen) {
        setSyncType(null);
      }
    }, [isOpen]);
    return (
      <Fragment>
        {renderTrigger(open)}
        <DqDatasetExportFormProvider
          datasetManifestHash={datasetManifestHash}
          syncType={syncType || undefined}
        >
          <DqDatasetSyncDrawer onClose={close} open={isOpen}>
            <Wizard key={isOpen.toString()}>
              <SelectSyncStep onClose={close} onSelect={[setSyncType]} />
              <CreateExportStep
                onClose={close}
                onSubmit={onSubmit}
                syncType={syncType!}
              />
              <ShowStatusStep onClose={close} syncType={syncType!} />
            </Wizard>
          </DqDatasetSyncDrawer>
        </DqDatasetExportFormProvider>
      </Fragment>
    );
  }
);
DatasetExportDialog.displayName = "DatasetExportDialog";

const WrappedDatasetExportDialog: React.FC<
  Pick<DatasetExportProviderProps, "datasetManifestHash"> &
    DatasetExportDialogProps
> = memo(({ datasetManifestHash, ...props }) => {
  return (
    <DatasetExportProvider datasetManifestHash={datasetManifestHash}>
      <DatasetExportDialog {...props} />
    </DatasetExportProvider>
  );
});
WrappedDatasetExportDialog.displayName = "WrappedDatasetExportDialog";

export default WrappedDatasetExportDialog;
