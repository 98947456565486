import { Box, Tooltip, Typography } from "@mui/joy";
import { styled, type Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";

const DataLabStatisticsTileBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  alignItems: "center",
  backgroundColor: grey[100],
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
  width: "100%",
}));

type DataLabStatisticsTileProps = {
  title: string;
  tooltipTitle: string;
  value: number;
};

const DataLabStatisticsTile: React.FC<DataLabStatisticsTileProps> = ({
  title,
  tooltipTitle,
  value,
}) => {
  const formatter = Intl.NumberFormat("en-US", { notation: "compact" });
  return (
    <Tooltip title={tooltipTitle}>
      <DataLabStatisticsTileBox>
        <Typography
          level="title-md"
          sx={{ color: "primary.main", marginBottom: 0.5 }}
        >
          {formatter.format(value)}
        </Typography>
        <Typography level="body-sm" sx={{ textAlign: "center" }}>
          {title}
        </Typography>
      </DataLabStatisticsTileBox>
    </Tooltip>
  );
};

DataLabStatisticsTile.displayName = "DataLabStatisticsTile";

export default memo(DataLabStatisticsTile);
