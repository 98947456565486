// TODO: Fix joy migration
import { Checkbox, FormControl, ListDivider, Option, Select } from "@mui/joy";
import difference from "lodash/difference";
import xor from "lodash/xor";
import { memo, useCallback, useMemo } from "react";

interface OptionType {
  title: string;
  value: string;
  __typename?: string;
}
interface PermissionsSelectProps {
  dataTestidSelectAll?: string;
  dataTestidOptionHelper?: string;
  dataTestidSelectButton?: string;
  options: OptionType[];
  permissions: string[];
  disabled?: boolean;
  onAddPermission: (value: string) => void;
  onRemovePermission: (value: string, title: string) => void;
  dataKey: string;
  disableSelectAll?: boolean;
  maxWidth?: string;
}

const ALL_OPTION = "all";

const DataRoomPermissionsSelect = ({
  dataTestidSelectAll,
  dataTestidSelectButton,
  dataTestidOptionHelper,
  disabled = false,
  options = [],
  permissions,
  onAddPermission,
  onRemovePermission,
  dataKey,
  disableSelectAll,
  maxWidth,
}: PermissionsSelectProps) => {
  const handleSelectAll = useCallback(() => {
    const permissionsValues = options.map(({ value }) => value);
    if (permissions.length !== options.length) {
      const addPermissions = difference(permissionsValues, permissions);
      addPermissions.forEach((value) => {
        onAddPermission(value);
      });
    } else {
      options.forEach(({ value, title }) => {
        onRemovePermission(value, title);
      });
    }
  }, [onAddPermission, onRemovePermission, options, permissions]);

  const availableOptions = useMemo(() => {
    return options.filter(
      ({ value }) => !disabled || permissions.includes(value)
    );
  }, [disabled, options, permissions]);

  const isAllSelected = useMemo(() => {
    return permissions.length === availableOptions.length;
  }, [permissions.length, availableOptions.length]);

  const handleSelectChange = useCallback(
    (event, value) => {
      if (!disabled) {
        const effectiveValue =
          typeof value === "string" ? value.split(",") : (value as string[]);
        const diff = xor(
          effectiveValue,
          isAllSelected ? [ALL_OPTION, ...permissions] : permissions || []
        );
        const focusedValue = diff[0];
        if (!focusedValue) {
          return;
        }
        if (focusedValue === ALL_OPTION) {
          handleSelectAll();
          return;
        }
        if (permissions.includes(focusedValue)) {
          onRemovePermission(
            focusedValue,
            options.find((o) => o.value === focusedValue)?.title || ""
          );
          return;
        }
        onAddPermission(focusedValue);
      }
    },
    [
      disabled,
      isAllSelected,
      permissions,
      onAddPermission,
      handleSelectAll,
      onRemovePermission,
      options,
    ]
  );

  const selectedValues = useMemo(() => {
    if (isAllSelected) {
      return [ALL_OPTION, ...permissions];
    }
    return permissions;
  }, [isAllSelected, permissions]);

  return (
    <FormControl>
      <Select<string[]>
        disabled={availableOptions.length === 0}
        multiple={true}
        onChange={handleSelectChange}
        placeholder={`No ${dataKey}s ${
          availableOptions.length === 0 ? "available" : "selected"
        }`}
        renderValue={(selected) => {
          if (selected.length === 1) {
            const selectedId = selected[0].value;
            const node = options.find(
              ({ value: nodeId }) => nodeId === selectedId
            );
            const { __typename = "", title } = node || {};
            return ["DraftMatchNode", "PublishedMatchNode"].includes(__typename)
              ? `${title} — Statistics`
              : title;
          } else {
            const selectedPermissions = options
              .filter(({ value: id }) =>
                selected.map(({ value }) => value).includes(id)
              )
              .map(({ title }) => title);
            return `${selectedPermissions.length} ${dataKey}s`;
          }
        }}
        slotProps={{
          button: {
            "data-testid": dataTestidSelectButton,
          },
          listbox: { variant: "outlined" },
        }}
        sx={{ backgroundColor: "transparent" }}
        value={selectedValues}
        variant="plain"
      >
        {!disableSelectAll && options.length > 1 && !disabled ? (
          <>
            <Option data-testid={dataTestidSelectAll} value={ALL_OPTION}>
              <Checkbox checked={isAllSelected} disabled={disabled} />
              Select all
            </Option>
            <ListDivider role="none" />
          </>
        ) : null}
        {availableOptions.map(({ __typename = "", value, title }) => (
          <Option
            data-testid={`${dataTestidOptionHelper}${title}`}
            disabled={disabled}
            key={value}
            value={value}
          >
            {!disabled && <Checkbox checked={permissions.includes(value)} />}
            {["DraftMatchNode", "PublishedMatchNode"].includes(__typename)
              ? `${title} — Statistics`
              : title}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
};

DataRoomPermissionsSelect.displayName = "DataRoomPermissionsSelect";

export default memo(DataRoomPermissionsSelect);
