import { testIds } from "@decentriq/utils";
import { FormControl, FormLabel, Input, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { useCreationWizardConfiguration } from "features/MediaInsightsDcr/contexts";

const DataRoomName: React.FC = () => {
  const { setDataRoomName, dataRoomName } = useCreationWizardConfiguration();
  return (
    <Stack>
      <Typography level="title-md">1. Data clean room configuration</Typography>
      <FormControl required={true}>
        <FormLabel>Name</FormLabel>
        <Input
          data-testid={
            testIds.mediaInsightsDcr.mediaInsightsDcrCreateCollaborationStep
              .insightDataRoomName
          }
          onChange={(event) => setDataRoomName(event.target.value)}
          placeholder={`e.g. "My media clean room"`}
          value={dataRoomName}
        />
      </FormControl>
    </Stack>
  );
};
DataRoomName.displayName = "DataRoomName";

export default memo(DataRoomName);
