const clearDecentriqStorage = () => {
  for (const storage of [localStorage, sessionStorage]) {
    const keysToRemove = [];
    for (let i = 0; i < storage.length; i++) {
      const key = storage.key(i);
      if (key && key.startsWith("dq:")) {
        keysToRemove.push(key);
      }
    }
    for (const key of keysToRemove) {
      storage.removeItem(key);
    }
  }
};

export default clearDecentriqStorage;
